<template>
    <div class="row">
        <div class="col-md-6">
            <seccion-datos titulo="Parámetros" @cambios="guardarDatos()" class="card card-success" :botonGuardar="permisomodif">
                
                <div class="row">
                    <div class="col-md-12">
                        <table class="table table-sm table-striped">
                            <thead>
                                <tr>
                                    <th>Carga trabajo</th>
                                    <th>Calidad</th>
                                    <th>Tiempo trabajo</th>
                                    <th>Coste medio</th>
                                    <th>Incidencias</th>
                                </tr>
                            </thead>
                            <tbody>                                
                                <tr>
                                   <td>
                                        <div style="display: flex; align-items: center;">
                                            <input :disabled="!permisomodif" class="form-control form-control-sm" type="number" v-model="datosoperario.carga_operario">
                                            <span>%</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div style="display: flex; align-items: center;">
                                            <input :disabled="!permisomodif" class="form-control form-control-sm" type="number" v-model="datosoperario.calidad_operario">
                                            <span>%</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div style="display: flex; align-items: center;">
                                            <input :disabled="!permisomodif" class="form-control form-control-sm" type="number" v-model="datosoperario.tiempo_medio_operario">
                                            <span>días</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div style="display: flex; align-items: center;">
                                            <input :disabled="!permisomodif" class="form-control form-control-sm" type="number" v-model="datosoperario.coste_medio_operario">
                                            <span>€</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div style="display: flex; align-items: center;">
                                            <input :disabled="!permisomodif" class="form-control form-control-sm" type="number" v-model="datosoperario.incidencias_operario">
                                            <span>%</span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </seccion-datos>
        </div>
        <div class="col-md-12">
            <seccion-datos titulo="Gráficas" @cambios="guardarDatos()" class="card card-success" :botonGuardar="false">                
                <div class="row">
                    <div class="col-md-6">
                        <div class="chart" style="position: relative;">
                            <Chart type="line" :data="datosCarga" :options="basicOptionsCarga" />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="chart" style="position: relative;">
                            <Chart type="line" :data="datosTiempos" :options="basicOptionsTiempos" />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="chart" style="position: relative;">
                            <Chart type="line" :data="datosCostes" :options="basicOptionsCostes" />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="chart" style="position: relative;">
                            <Chart type="line" :data="datosIncidencias" :options="basicOptionsCarga" />
                        </div>
                    </div>
                </div>
            </seccion-datos>
        </div>
    </div>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';

export default {
    components: {
  
    },
    props: ['id', 'operario', 'permisomodif'],
    data() {
        return {
            datosoperario:'',
            datosCarga: {
                labels: '',
                datasets: [
                    {
                        label: 'Carga',
                        data: [],
                        fill: false,
                        borderColor: '#42A5F5',
                        tension: .4
                    },
                ]
            },
            datosTiempos: {
                labels: '',
                datasets: [
                    {
                        label: 'Tiempos medios',
                        data: [],
                        fill: false,
                        borderColor: '#42A5F5',
                        tension: .4
                    },
                ]
            },
            datosCostes: {
                labels: '',
                datasets: [
                    {
                        label: 'Costes medios',
                        data: [],
                        fill: false,
                        borderColor: '#42A5F5',
                        tension: .4
                    },
                ]
            },
            datosIncidencias: {
                labels: '',
                datasets: [
                    {
                        label: 'Incidencias',
                        data: [],
                        fill: false,
                        borderColor: '#42A5F5',
                        tension: .4
                    },
                ]
            },
            basicOptionsCarga: {
                plugins: {
                    legend: {
                        tooltip: {
                            callbacks: {
                                label: function(context) {
                                    return context.raw + '%';
                                }
                            }
                        },
                        labels: {
                            color: '#495057'
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    },
                    y: {
                        ticks: {
                            color: '#495057',
                            callback: function(value) {
                                return (parseFloat(value).toFixed(1)).toString() + '%';
                            }
                        },
                        grid: {
                            color: '#ebedef'
                        },
                        
                    }
                }
            },
            basicOptionsTiempos: {
                plugins: {
                    legend: {
                        tooltip: {
                            callbacks: {
                                label: function(context) {
                                    return context.raw + 'ds';
                                }
                            }
                        },
                        labels: {
                            color: '#495057'
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    },
                    y: {
                        ticks: {
                            color: '#495057',
                            callback: function(value) {
                                return (parseFloat(value).toFixed(2)).toString() + 'ds';
                            }
                        },
                        grid: {
                            color: '#ebedef'
                        },
                        
                    }
                }
            },
            basicOptionsCostes: {
                plugins: {
                    legend: {
                        tooltip: {
                            callbacks: {
                                label: function(context) {
                                    return context.raw + '€';
                                }
                            }
                        },
                        labels: {
                            color: '#495057'
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    },
                    y: {
                        ticks: {
                            color: '#495057',
                            callback: function(value) {
                                return (parseFloat(value).toFixed(2)).toString() + '€';
                            }
                        },
                        grid: {
                            color: '#ebedef'
                        },
                        
                    }
                }
            },
        }
    },
    methods: {
        obtenerUltimos12Meses() {
            const meses = [
                'Ene',
                'Feb',
                'Mar',
                'Abr',
                'May',
                'Jun',
                'Jul',
                'Ago',
                'Sep',
                'Oct',
                'Nov',
                'Dic',
            ];
            const resultado = [];
            const fechaActual = new Date();

            for (let i = 0; i < 12; i++) {
                const mes = fechaActual.getMonth(); // Número del mes actual (0 = Enero, 11 = Diciembre)
                const año = fechaActual.getFullYear().toString().slice(-2); // Últimos dos dígitos del año

                resultado.unshift(`${meses[mes]} ${año}`);

                // Restar un mes
                fechaActual.setMonth(fechaActual.getMonth() - 1);
            }

            return resultado;
        },
        async cargarGraficas(){
            const api = new PwgsApi();
            const resp = await api.get('operarios/'+this.id+'/graficas');
            this.datosCarga.datasets[0].data = Object.values(resp.carga).reverse().map((function(x){
                return parseFloat(x).toFixed(1);
            }));
            console.log('datoscarga', this.datosCarga);
            this.datosCarga.labels = this.obtenerUltimos12Meses();
            this.datosTiempos.datasets[0].data = Object.values(resp.tiempo_medio).reverse().map((function(x){
                return parseFloat(x).toFixed(2);
            }));
            this.datosTiempos.labels = this.obtenerUltimos12Meses();
            this.datosCostes.datasets[0].data = Object.values(resp.coste_medio).reverse().map((function(x){
                return parseFloat(x).toFixed(2);
            }));
            this.datosCostes.labels = this.obtenerUltimos12Meses();
            this.datosIncidencias.datasets[0].data = Object.values(resp.indidencias).reverse().map((function(x){
                return parseFloat(x).toFixed(1);
            }));
            this.datosIncidencias.labels = this.obtenerUltimos12Meses();
        },
        async guardarDatos(){
            const api = new PwgsApi();
            try{
                await api.put('operarios/'+this.id, {
                    carga_operario:this.datosoperario.carga_operario, 
                    calidad_operario:this.datosoperario.calidad_operario, 
                    tiempo_medio_operario:this.datosoperario.tiempo_medio_operario, 
                    coste_medio_operario:this.datosoperario.coste_medio_operario, 
                    incidencias_operario:this.datosoperario.incidencias_operario  
                })
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos actualizados correctamente', life: 2000 });
            }catch(e){
                this.$toast.add({ severity: 'error', summary: 'Error', detail: e, life: 5000 });
            }
        }
    },
    watch: {
        operario(){
            this.datosoperario = this.operario;
            this.cargarGraficas();
        }
    },
    mounted() {        
        if(this.operario){
            this.datosoperario = this.operario;
            this.cargarGraficas();
        }
    }
}
</script>